import React from 'react'
import { NavLink } from 'react-router-dom'
import { Icon } from '../Icon'
import { NavbarMenuOptionType } from './'

interface MenuOptionProps extends NavbarMenuOptionType {}

export const MenuOption = ({ icon, name, action, to }: MenuOptionProps) => {
  return (
    <React.Fragment>
      {to ? (
        <NavLink
          className={`g-item text-decoration-none`}
          onClick={action}
          to={to}
          isActive={(match, location) => {
            if (!match || to) {
              return false
            }
            return location.pathname.startsWith(to)
          }}
        >
          <OptionContent icon={icon} name={name} />
        </NavLink>
      ) : (
        <span className='g-item text-decoration-none' onClick={action}>
          <OptionContent icon={icon} name={name} />
        </span>
      )}
    </React.Fragment>
  )
}

const OptionContent = ({
  icon,
  name
}: Pick<NavbarMenuOptionType, 'icon' | 'name'>) => {
  return (
    <React.Fragment>
      {icon && (
        <div className='pr-0 pr-sm-2'>
          <Icon name={icon} size='12px' />
        </div>
      )}
      <span className='d-none d-sm-block'>{name}</span>
    </React.Fragment>
  )
}
