import React from 'react'
import { IconName } from '../Icon/IconPaths'
import { MenuOption } from './MenuOption'

export type NavbarMenuOptionType = {
  icon: keyof typeof IconName
  name: string
  action?: () => void
  to?: string
}

export type NavbarProps = {
  /**
   * Componente con el logo del producto
   */
  Logo: () => JSX.Element
  /**
   * Opciones del menú
   */
  menuOptions: NavbarMenuOptionType[]
}

/**
 * Componente utilizado para renderizar barra de navegación superior
 * por defecto utiliza los colores primario y secundario de la marca
 * <br/>Contiene logo de la marca y opciones de menú.
 * <br/>NOTA: para utilizar esta barra el componente debe estar dentro de un componente
 * BrowserRouter de react-router-dom
 */
export const Navbar = ({ Logo, menuOptions }: NavbarProps) => {
  return (
    <React.Fragment>
      <div className='g-navbar'>
        <div className='g-brand-logo'>
          <Logo />
        </div>

        <div className='g-items-container'>
          {menuOptions?.map((option, i) => (
            <MenuOption key={`menu${i}`} {...option} />
          ))}
        </div>
      </div>
    </React.Fragment>
  )
}
